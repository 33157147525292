import { Component, OnInit, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { DirectDebitReminderDialogComponent } from 'src/app/direct-debit-dialog/direct-debit-dialog.component';
import { AccountService } from 'src/app/core/services/account.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { BannerNotificationService } from 'src/app/core/services/banner-notification.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BannerNotificationComponent } from 'src/app/banner-notification/banner-notification.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() extraClass: string;
  @Input() headerImage: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() imageLink: string;
  @Input() hideDebitStatus: boolean;
  directDebitEnabled = true;
  directDebitLink = '';
  userActive: boolean;
  showNotifications: boolean;
  notifications = [];
  characters = 150

  constructor(
    private bannerService: BannerNotificationService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private authService: AuthService) {
    this.userActive = localStorage.getItem('active') === 'true';
    this.matIconRegistry.addSvgIcon(
      'exclamation_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/exclamation_icon.svg')
    );
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      if (result.matches) {
        this.characters = 30
      }
    });
    breakpointObserver.observe([
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.characters = 75
      }
    });
  }

  ngOnInit() {
    if (this.userActive) {
      if (!this.hideDebitStatus) {
        this.fetchBannerNotifications();
        this.fetchAccountInfo();
      } else {
        return;
      }
    }
  }

  fetchAccountInfo() {
    this.accountService.getDirectDebitLinks().subscribe(resp => {
      if (resp) {
        const currentDate = new Date();
        const response = resp['response'];
        this.directDebitEnabled = response['enabled'];
        this.directDebitLink = response['direct_debit_link'];

        // Display direct debit reminder dialog if needed
        if (!this.directDebitEnabled) {
          const directDebitDateReminderString: string | null = localStorage.directDebitReminderDate;

          if (directDebitDateReminderString == null) {
            this.setLocalStorageValues();
            const dialogRef = this.dialog.open(DirectDebitReminderDialogComponent);
            dialogRef.componentInstance.setUpDirectDebit.subscribe(result => {
              if (result === true) {
                if (this.directDebitLink) {
                  window.open(this.directDebitLink, '_self');
                }
              }
            });
          } else {

            const reminderDate = new Date(directDebitDateReminderString);

            if (reminderDate < currentDate) {
              this.setLocalStorageValues();
              const dialogRef = this.dialog.open(DirectDebitReminderDialogComponent);
              dialogRef.componentInstance.setUpDirectDebit.subscribe(result => {
                if (result === true) {
                  if (this.directDebitLink) {
                    window.open(this.directDebitLink, '_self');
                  }
                }
              });
            }
          }
        }
      }

    }, err => {
      console.error('GET DIRECT DEBIT STATUS FETCH ERROR! ', err);
    });
  }

  fetchBannerNotifications() {
    this.bannerService.getBannerNotifications().subscribe(res => {
      if (res) {
        this.notifications = res['banner_notifications'];

        if (this.notifications) {
          this.showNotifications = true;
        }
      }
    }, err => {
      console.error('GET BANNER NOTIFICATIONS STATUS FETCH ERROR! ', err);
    });
  }

  openDialog(notification: { message: string, id: number, vax: boolean }) {
    let dialogRef = this.dialog.open(BannerNotificationComponent, {
      data: { body: notification }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'read') {
        this.bannerService.sendReadBannerNotification(notification.id).subscribe(res => {
          this.notifications = this.notifications.filter((banner) => banner.id != notification.id)
        })
      }
    })
  }

  navigateTo(path: string) {
    if (this.directDebitLink) {
      this.googleEvent('banner', 'enable direct debit');
      window.open(this.directDebitLink, '_self');
    }
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'page top - ' + section, 'direct debit', 10);
  }

  setLocalStorageValues(): void {
    const currentDate = new Date();
    const reminderDate = currentDate.setDate(currentDate.getDate() + 11);
    localStorage.directDebitReminderDate = reminderDate;
  }

}
