import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MobileAppService } from './core/services/mobile-app.service';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'bddc-customer-portal';
  @Output() pageReady: EventEmitter<boolean> = new EventEmitter();

  constructor(
    router: Router,
    private mobileAppService: MobileAppService) {
    this.pageReady.emit(false);
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', environment.google_analytics_id, {
        page_path: event.urlAfterRedirects
      });
    });
  }

  ngAfterViewInit(): void {
    this.pageReady.emit(true);
    this.mobileAppService.startAppChecker();
  }
}
