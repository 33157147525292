import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxProgressiveImageLoaderModule, IImageLoaderOptions } from 'ngx-progressive-image-loader';
import { environment } from 'src/environments/environment';

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { AuthService } from './core/services/auth.service';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HeaderModule } from './core/header/header.module';
import { MainModule } from './modules/main/main.module';
import { HomeModule } from './modules/main/pages/home/home.module';
import { AuthInterceptor } from './core/helpers/auth-interceptor';
import { ErrorInterceptor } from './core/helpers/error-inerceptor';
import { GooleAnalyticsEventsService } from './core/services/goole-analytics-events.service';
import { ErrorHandlerModule } from './core/error-handler.module';
import { EmailRatingComponent } from './core/email/email-rating/email-rating.component';
import { LoginComponent } from './core/login/login.component';
import { NotificationDialogComponent } from './shared/components/notification/notification-dialog/notification-dialog.component';
import { SessionDialogComponent } from './modules/core/session/session-dialog/session-dialog.component';
import { TermsComponent } from './shared/components/info/terms/terms.component';
import { PrivacyComponent } from './shared/components/info/privacy/privacy.component';
import { FaqComponent } from './shared/components/info/faq/faq.component';
import { CookiesComponent } from './shared/components/info/cookies/cookies.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserHelper } from 'src/app/core/helpers/browser-helper';
import { errorHandlerFactory } from './core/providers/error-monitor.provider';
import { DownloadAppNotificationComponent } from './shared/components/download-app-notification/download-app-notification.component';
import { BannerNotificationComponent } from './banner-notification/banner-notification.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { UploadVaccinationComponent } from './banner-notification/upload-vaccination/upload-vaccination.component';
import { MobileAppService } from './core/services/mobile-app.service';
import { DirectDebitDialogComponent } from './modules/main/pages/billing/invoice-details/direct-debit/direct-debit-dialog.component';
import { SwapDayCutOffDialogComponent } from './swap-day-cut-off-dialog/swap-day-cut-off-dialog.component';
import { DirectDebitReminderDialogComponent } from './direct-debit-dialog/direct-debit-dialog.component';
import { AdventCalendarComponent } from './advent-calendar/advent-calendar.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EmailRatingComponent,
    NotificationDialogComponent,
    SessionDialogComponent,
    TermsComponent,
    PrivacyComponent,
    FaqComponent,
    CookiesComponent,
    DownloadAppNotificationComponent,
    BannerNotificationComponent,
    UploadVaccinationComponent,
    DirectDebitDialogComponent,
    SwapDayCutOffDialogComponent,
    DirectDebitReminderDialogComponent,
    AdventCalendarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule.forRoot(),
    ErrorHandlerModule.forRoot(),
    NgxProgressiveImageLoaderModule.forRoot(
      // tslint:disable-next-line: no-angle-bracket-type-assertion
      <IImageLoaderOptions>{
        // rootMargin must be specified in pixels or percent
        rootMargin: '30px',
        threshold: 0.1,
        // css filter
        // filter: 'blur(3px) drop-shadow(0 0 0.75rem crimson)',
        imageRatio: 16 / 9,
        placeholderImageSrc:
          './../assets/images/backgrounds/photos/photos_placeholder_desktop.png'
      }
    ),
    MainModule,
    HomeModule,
    CoreModule,
    HeaderModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthService,
    AuthInterceptor,
    ErrorInterceptor,
    CookieService,
    BrowserHelper,
    GooleAnalyticsEventsService,
    MobileAppService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
