<div class="direct-debit-dialog">
  <div class="direct-debit-dialog-title">
    <h1 mat-dialog-title>Reminder</h1>
  </div>
  <mat-dialog-content>
    There is a problem with your account. We've noticed you aren't set up for direct debit. We recommend
    switching to payment via direct debit as soon as possible for ease of payment, it only takes a minute to set up.
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" mat-dialog-close (click)="onSetUp()">Set Up</button>
  </mat-dialog-actions>
</div>